import './modules/script';
import './modules/map/mapalex';

import AOS from 'aos';
// ..
AOS.init();
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});

onElementHeightChange(document.body, function(){
  AOS.refresh();
  ScrollTrigger.refresh();
});


function onElementHeightChange(elm, callback) {
    var lastHeight = elm.clientHeight
    var newHeight;

    (function run() {
        newHeight = elm.clientHeight;
        if (lastHeight !== newHeight) callback();
        lastHeight = newHeight;

        if (elm.onElementHeightChangeTimer) {
          clearTimeout(elm.onElementHeightChangeTimer);
        }

        elm.onElementHeightChangeTimer = setTimeout(run, 150);
    })();
  }

// GSAP
// ------------------------------------------- //
//H2 animation
const sections = gsap.utils.toArray('.section');
sections.forEach((section, index) => {
    const heading = section.querySelector('h2');
    let split = SplitText.create(heading, { type: "words" });

	//create an animation for each heading
// 	let animation = gsap.from(split.words, {
//     opacity: 0,
//     stagger: {
//     amount: 1,
//     from: "random"
//   },ease: "linear",
// });

  let animation=gsap.fromTo(
      split.words,
      {
          y: 50,
          x:-20,
          opacity: 0,
      },
      {
          y: 0,
          x:0,
          opacity: 1,
          stagger: 0.1,
          duration: 1,
          ease: 'power4.out',
      }
  );


		ScrollTrigger.create({
		trigger: section,
		start: "top center",
		toggleActions: "play none none pause",
		animation: animation,
    markers:false,
    pinSpacing:false,
	});

});

// ------------------------------------------- //
/*Menu Hover*/
const menu_items = gsap.utils.toArray('.menu-item');

menu_items.forEach((sections, index) => {
    const menu_heading = sections.querySelector('.menu_chars');
    const menu_image = sections.querySelector('.menu_image img');
    let split_menu = SplitText.create(menu_heading, { type: "chars" });
    const chars = split_menu.chars;

    // Define the GSAP animation function
    const animateMenu = () => {
        gsap.fromTo(
            chars,
            {
                y: 100,
                x:-20,
                opacity: 0,
            },
            {
                y: 0,
                x:0,
                opacity: 1,
                stagger: 0.05,
                duration: 0.5,
                ease: 'power4.out',
            }
        );
    };

    // Add mouseenter event listener
    sections.addEventListener('mouseenter', () => {
        animateMenu();
    });

    // Add mouseleave event listener (if you want to reverse the animation)
    sections.addEventListener('mouseleave', () => {
        // Optionally reverse the animation on mouseleave
        gsap.to(chars, {
            y: 0,
            x:0,
            opacity: 0,
            stagger: 0,
            duration: 0,
            ease: 'power4.out',
        });

        gsap.to(menu_image, {
           scale: 0,
           duration: 0.3,
           ease: 'power4.out'
       });
    });

    // Add mousemove event listener to smoothly move menu image with cursor
     sections.addEventListener('mousemove', (event) => {
         const rect = menu_image.getBoundingClientRect();
         const imageCenterX = rect.left + rect.width / 2;
         const imageCenterY = rect.top -30;
         //+ rect.height / 2
         gsap.to(menu_image,{
             x: event.clientX - imageCenterX,
             y: event.clientY - imageCenterY,
             duration: 1,
             ease: 'power2.out',
         }
       );
     });

     // Reset menu image position on mouseleave
     sections.addEventListener('mouseleave', () => {
         gsap.to(menu_image, {
             x: 0,
             y: 0,
             scale: 1, // Reset scale
             duration: 0.3,
             ease: 'power2.out',
         });
     });
});





gsap.config({trialWarn:false});
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);


// ------------------------------------------- //
/*Landing animation*/
let animation_landing = gsap.to( ".pinned-image",{
  scale:1.3,
});

ScrollTrigger.create({
trigger: ".mask",
start: "top",
end:"+=300",
scrub:true,
animation: animation_landing,
markers: false,
pin:true,
pinSpacing:false,
toggleClass: {targets: ".mask", className: "active"}
});

/*End lannding animation*/

// ------------------------------------------- //
// Smooth scroller
let smoother = ScrollSmoother.create({
  wrapper: '#smooth-wrapper',
  content: '#smooth-content',
  smooth:2,
  smoothTouch:0.1,
  effects:true
});

smoother.effects(".image_parallax img",{speed:"auto"});
