var mapStyle =
[
    {
        "featureType": "all",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#273951"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f5f3ea"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffd07b"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#baced2"
            },
            {
                "visibility": "on"
            }
        ]
    }
];


/*id of map here*/
if ($("#map").length > 0) {
  google.maps.event.addDomListener(window, 'load', initialize_map);

}

var directionsService = new google.maps.DirectionsService();
var directionsRenderer = new google.maps.DirectionsRenderer({suppressMarkers: true});

// Start/Finish icons
 var icons = {
  start: new google.maps.MarkerImage(
   // URL
   'https://demo.limecreative.gr/marikahotel/wp-content/themes/marika/assets/images/marker_small.png',
   // (width,height)
  ),
  end: new google.maps.MarkerImage(
   // URL
   'https://demo.limecreative.gr/marikahotel/wp-content/themes/marika/assets/images/marker_small.png',

  )
 };

var map;

function initialize_map() {


  var controlPosition = google.maps.ControlPosition.RIGHT_CENTER;
  var coords_dynamic = jQuery("#map").attr('data-coords');
  var splitedcoords = coords_dynamic.split(',');

  var myLatlng = new google.maps.LatLng(splitedcoords[0], splitedcoords[1]);

  var isDraggable = true;
  var mapOptions = {
    zoom: 11,
    gestureHandling: 'cooperative',
    center: myLatlng,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
     styles:mapStyle,
    scrollwheel: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles:mapStyle
  };


   map = new google.maps.Map(document.getElementById('map'),mapOptions);
  directionsRenderer.setMap(map);

  var iconsource = 'https://demo.limecreative.gr/marikahotel/wp-content/themes/marika/assets/images/marker.png';/*map pointer here*/
  var icons = {
    markericon: {
      icon: iconsource
    }
  };

  var marker = new google.maps.Marker({
    position: myLatlng,
    icon: {
    url: "https://demo.limecreative.gr/marikahotel/wp-content/themes/marika/assets/images/marker.png"
    },
    map: map
  });

  marker.setAnimation(google.maps.Animation.BOUNCE);
  setTimeout(function(){ marker.setAnimation(null); }, 1000);

  //Map style is not mandatory
  var styledMap = new google.maps.StyledMapType(mapStyle, {
    name: "Styled Map"
  });

  map.mapTypes.set('mapStyle', styledMap);
  map.setMapTypeId('mapStyle')



}

jQuery(".map_directions p").on('click', function() {
    var start =jQuery(this).attr('data-start');
    var end = jQuery(this).attr('data-end');
    calcRoute(start,end);
})

function calcRoute(start_point,end_point) {
  var start =start_point;
  var end = end_point;

  var request = {
    origin: start,
    destination: end,
    travelMode: 'DRIVING'
  };
  directionsService.route(request, function(result, status) {
    if (status == 'OK') {
      directionsRenderer.setDirections(result);

     var leg = result.routes[ 0 ].legs[ 0 ];
     makeMarker( leg.start_location, icons.start, "title" );
     makeMarker( leg.end_location, icons.end, "title" );
    }
  });
}


function makeMarker( position, icon, title ) {
 new google.maps.Marker({
  position: position,
  map: map,
  icon: icon,
  title: title
 });
}
