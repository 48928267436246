/*Gallery change background image*/
$( ".galleries").each(function( index ) {
  $(this).hover(function() {
          var background_image=$(this).data('image');
          console.log(background_image);
          $("#gallery-page").css("background-image", "url(" + background_image + ")");
   });
});

/*scroll header*/
$(window).scroll(function() {
  if ($(document).scrollTop() > 700) {
      $('.header_wrapper').addClass('scrolled');
  }
  else{
    $('.header_wrapper').removeClass('scrolled');
  }
});

/*Detect Direction*/
var lastScrollTop = 0;
$(window).scroll(function(event){
   var st = $(this).scrollTop();
   if (st > lastScrollTop){
       // downscroll code
       $('.header_wrapper').addClass('down');
   } else {
      // upscroll code
      $('.header_wrapper').removeClass('down');
   }
   lastScrollTop = st;
});



/*Language Switcher*/
$(".language.is-active").on('click', function() {
    return false;
})

/*Filter*/
$(".filter p").on('click', function() {
  ScrollTrigger.refresh();
    var filter=$(this).data('filter');
    if($(this).hasClass('active')){
      $(this).removeClass('active');
    }else{
      $(".filter p").removeClass('active');
      $(this).addClass('active');
    }
    $( "#accommodation .wrapper .room").each(function( index ) {
          var room_filter=$(this).data('filter');
          if(filter=='All'){
                $( "#accommodation .wrapper .room").addClass('show');
                $( "#accommodation .wrapper .room").removeClass('hidden');
          }else{
            if(filter==room_filter){
              $(this).addClass('show');
              $(this).removeClass('hidden');
            }else{
                $(this).removeClass('show');
                $(this).addClass('hidden');
            }
          }
    });
    return false;
});

console.log('test')
$(document).ready(function() {
  console.log('test1')
  const filter = window.location.hash
  console.log('test'+filter)
  if (filter) {
    console.log('test2')
    $(`.filter p[data-filter="${filter.slice(1)}"]`).click()
  }
})

/*Faq*/
$(".faq").on('click', function() {
    $(this).find('.hidden_content').slideToggle();
    if($(this).find('.button').hasClass('active')){
      $(this).find('.button').removeClass('active');
    }else{
      $(this).find('.button').addClass('active');
    }
    return false;
});

/*Open Menu*/
$(".menu_button").on('click', function() {
      if($(this).hasClass('is-active')){
          $("body").css('overflow-y','scroll');
          $(this).removeClass('is-active');
          $('.menu_button').removeClass('is-active');
          $('.headermenu').removeClass('is-active');
        }
        else{
          $(this).addClass('is-active');
          $('.menu_button').addClass('is-active');
          jQuery("body").css('overflow-y','hidden');
          $('.headermenu').addClass('is-active');
      }
});



/*Gallery*/
          import Swiper, { Navigation } from 'swiper';
          const gallery_swi = new Swiper(".gallery_swipe", {
            loop: true,
            spaceBetween: 200,
            grabCursor: true,
            slidesPerView:2,
            speed: 1000,
            centeredSlides: true,
            breakpoints: {
             // when window width is >= 320px
             320: {
               spaceBetween: 30,
             },
             // when window width is >= 768px
             768: {
               spaceBetween: 70,
             },
             // when window width is >= 1024px
             1024: {
               spaceBetween: 100,
             },
             // when window width is >= 1200px
             1200: {
               spaceBetween: 200,
             }
           }
          });

          const review_swi = new Swiper(".review_swipe", {
            loop: true,
            spaceBetween:20,
            grabCursor: false,
            slidesPerView:1,
            speed:500,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });

          const gastronomy_swi = new Swiper(".gastronomy_swipe", {
            loop: false,
            spaceBetween:80,
            grabCursor: true,
            slidesPerView:2.5,
            centeredSlides: false,
            speed:1000,
            breakpoints: {
             // when window width is >= 300px
             300: {
               slidesPerView:1.5,
             },
             // when window width is >= 768px
             768: {
               slidesPerView:1.5,
             },
             // when window width is >= 1024px
             1024: {
               slidesPerView:2.5,
             },
             // when window width is >= 1200px
             1200: {
               slidesPerView:2.5,
             }
           }
          });

// footer time
setInterval(function() {
  $('.footer-time').each(function() {
    var d = new Date();
    var h = d.getHours();
    var m = d.getMinutes();
    $(this).text(h + ':' + m);
  });
}, 60000);